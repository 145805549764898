import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import 'mburger-webcomponent'
import "mmenu-js";
import VanillaTilt from 'vanilla-tilt';


import GLightbox from 'glightbox';//Lightbox
const lightbox = GLightbox({
  touchNavigation: true,
  selector: 'data-lightbox',
  videosWidth: '80vw'
});

Alpine.plugin(collapse)
window.Alpine = Alpine
Alpine.start()

	VanillaTilt.init(document.querySelectorAll("[data-tilty]"), {
  max: 15,
  speed: 3000,
  glare: true,
  "max-glare": 0.3,
  scale: 1.05,
  reverse: true
	});

import Headroom from "headroom.js";

// Check if the element with the class "headroom" exists
var myElement = document.querySelector(".headroom");
if (myElement) {
    // If the element exists, initialize Headroom
    var headroom = new Headroom(myElement, {
        offset: 100,
        tolerance: 20
    });
    // initialise
    headroom.init();
}